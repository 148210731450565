.navbarContainer {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: aqua;
}

.navbarContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 72px;
    background-color: var(--primary-900);
}

.navbarTitle {
    flex: 1;
}

.navbarButtonsContainer {
    flex: 1;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;
}

.navbarRightPadding {
    display: initial;
    flex: 1;
}

.navbarDrawerContainer {
    display: none;
}

.navbarOpenDrawerButton {
    display: flex;
    flex: 1;
    justify-content: flex-end;  
}

.navbarDrawer {
    color: black;
}

.navbarAlertBar {
    background-color: var(--neutral-700);
    color: var(--neutral-white);
    padding: '4px';
    text-align: center;
}

/* Mobile */
@media only screen and (max-width: 899px) {
    .navbarContent {
        padding: 0px 24px;
    }

    .navbarButtonsContainer {
        display: none;
    }

    .navbarRightPadding {
        display: none;
    }

    .navbarDrawerContainer {
        display: initial;
    }
}

/* Tablet */
@media only screen and (min-width: 899px) and (max-width: 1440px) {
    .navbarContent {
        padding: 0px 32px;
    }

    .navbarButtonsContainer {
        justify-content: flex-end;
    }
    
    .navbarRightPadding {
        display: none;
    }
}

/* Desktop */
@media only screen and (min-width: 1440px) {
    .navbarContent {
        padding: 0px 32px;
    }

    .navbarButtonsContainer {
        justify-content: center;
    }
    
}
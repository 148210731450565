
.servicesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px 24px 80px 24px;
    gap: 30px;
    flex: 1;
}

.servicesTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.servicesSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    gap: 32px;
    max-width: 648px;
    width: 100%;
}

.servicesSectionItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.serviceItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    align-self: stretch;
    position: relative;
}

/* .serviceItemName {
    
} */

.serviceItemPrice {
    width: 32px;
}

.serviceItemDashedLine {
    position: relative;
    top: 4px;
    flex: 1;
    border-top: 3px dotted #FFB8DE;
}
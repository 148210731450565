
.galleryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    padding-bottom: 80px;
    flex: 1;
}

.galleryImagesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px 0px 0px;
    gap: 16px;
    width: 100%;
}

/* .galleryImage {
    width: 256px;
    height: 256px;
}

.galleryImageLarge {
    width: 512px;
    height: 512px;
} */

/* Mobile */
@media only screen and (max-width: 899px) {
    #galleryImageList {
        padding: 0px 0px 0px 0px;
    }
}

/* Tablet */
@media only screen and (min-width: 899px) {
    #galleryImageList {
        padding: 0px 32px 0px 32px;
    }
}

/* Desktop */
@media only screen and (min-width: 1440px) {
    #galleryImageList {
        padding: 0px 52px 0px 52px;
    }
}
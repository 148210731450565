.footerContainer {
    z-index: 100;
    display: flex;
    flex-direction: column;
}

.footerTop {
    display: flex;
    background-color: var(--neutral-900);
    color: var(--neutral-100);
}

.footerBottom {
    display: flex;
    padding: 8px;
    text-align: center;
    justify-content: center;
    background-color: var(--neutral-700);
    color: var(--neutral-white);
}

.footerSection {
    display: flex;
    flex-direction: column;
}

/* Mobile */
@media only screen and (max-width: 899px) {
    .footerTop {
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px 24px;
        gap: 24px;
    }

    .footerSection {
        align-items: center;
    }
}

/* Tablet */
@media only screen and (min-width: 899px) {
    .footerTop {
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 32px;
    }

    .footerSection {
        align-items: flex-start;
    }
}

/* Desktop */
@media only screen and (min-width: 1440px) {
    .footerTop {
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 48px;
    }

    .footerSection {
        align-items: flex-start;
    }
}

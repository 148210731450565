.aboutHoursContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.aboutSchedule {
    display: flex;
    flex-direction: row;
    gap: 24px;
    background-color: #F4F4F4;
}

.aboutDayContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.aboutTimeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
}
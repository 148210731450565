
.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    padding: 0px 128px;
    margin: auto;
    gap: 56px;
    flex: 1;
}

.aboutHoursAndLocationContainer {
    display: flex;
    flex-direction: row;
    max-width: 1320px;
    gap: 24px;
}

.aboutHoursContainer {
    display: flex;
}

.aboutLocationContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 543px;
    height: 560px;
}

.aboutMapAndAddressContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.aboutMap {
    width: 514px;
    height: 364px;
}

.aboutReviewsContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 50px 0px;
    gap: 56px;
}

.aboutReviewsRow {
    display: flex;
    gap: 24px;
}


/* Mobile */
@media only screen and (max-width: 899px) {
    .aboutContainer {
        align-items: stretch;
        padding: 0px 24px;
    }

    .aboutHoursAndLocationContainer {
        flex-direction: column-reverse;
        align-items: center;
        padding: 56px 24px 0px 24px;
    }

    .aboutHoursContainer {
        align-items: center;
    }

    .aboutLocationContainer {
        align-items: center;
    }

    .aboutMapAndAddressContainer {
        align-items: center;
        width: 100%;
    }

    .aboutReviewsContainer {
        align-items: center;
        text-align: center;
    }

    .aboutReviewsRow {
        flex-direction: column;
        align-items: center;
    }
}

/* Tablet */
@media only screen and (min-width: 899px) {
    .aboutContainer {
        padding: 0px 32px;
    }

    .aboutHoursAndLocationContainer {
        flex-direction: row;
        padding: 52px 0px 0px 0px;
    }

    .aboutHoursContainer {
        align-items: center;
    }

    .aboutLocationContainer {
        align-items: center;
    }

    .aboutMapAndAddressContainer {
        align-items: center;
        width: 100%;
    }

    .aboutReviewsContainer {
        align-items: center;
        text-align: center;
    }

    .aboutReviewsRow {
        flex-direction: column;
        align-items: center;
    }
}

/* Desktop */
@media only screen and (min-width: 1440px) {
    .aboutHoursAndLocationContainer {
        flex-direction: row;
        padding: 52px 0px;
    }

    .aboutHoursContainer {
        align-items: initial;
    }

    .aboutLocationContainer {
        align-items: flex-start;
    }

    .aboutMapAndAddressContainer {
        align-items: flex-start;
        width: 514px;
    }

    .aboutReviewsContainer {
        align-items: flex-start;
        text-align: initial;
    }

    .aboutReviewsRow {
        flex-direction: row;
        align-items: initial;
        width: 1094px;
    }
}
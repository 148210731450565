
.homeContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background: linear-gradient(0deg, rgba(255, 184, 222, 0.85), rgba(255, 184, 222, 0.85))
}

.homeSection {
    display: flex;
    flex-direction: column;
    max-width: 424px;
    gap: 16px;
}

.homeLargeText {
    display: flex;
    flex-direction: column;
}

.homeButtons {
    width: 249px;
    padding: 16px;
    background-clip: content-box;
}

/* Mobile */
@media only screen and (max-width: 899px) {
    .homeContainer {
        align-items: center;
        text-align: center;
        padding: 64px 24px;
        gap: 16px;
    }
}

/* Tablet */
@media only screen and (min-width: 899px) {
    .homeContainer {
        justify-content: center;
        align-items: center;
        padding: 64px 32px;
        gap: 16px;
        text-align: center;
    }
}

/* Desktop */
@media only screen and (min-width: 1440px) {
    .homeContainer {
        gap: 88px;
    }
}


/* const styles = {
    container: {
        display: 'inline-block',
        height: '75vh',
        width: '100%',
    },
    containerTablet: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        gap: '16px',

        position: 'relative',
        height: '75vh',
        left: '0px',
        right: '0px',
        top: 'calc(50% - 813px/2 - 50px)',

        textAlign: 'center',

        background: 'radial-gradient(50% 50% at 50% 50%, rgba(255, 184, 222, 0.85) 93.86%, rgba(244, 244, 244, 0) 100%), url(images/HomeImageLarge2.png)',
    },
    containerMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '128px 0px 0px 0px',
        gap: '16px',

        position: 'relative',
        height: '75vh',
        left: '0px',
        right: '0px',
        top: 'calc(50% - 813px/2 - 50px)',

        textAlign: 'center',

        background: 'linear-gradient(0deg, rgba(255, 184, 222, 0.85), rgba(255, 184, 222, 0.85)), url(images/HomeImageLarge2.png)',
    },
    slogan: {
        display: 'flex',
        flexDirection: 'column',

        position: "absolute",
        width: "424px",
        height: "100px",
        left: "60px",
        top: "156px",
    },
    sloganNotDesktop: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '424px',
        width: '100%',
    },
    largeText: {
        position: "absolute",
        left: "60px",
        top: "432px",
    },
    largeTextNotDesktop: {
        display: 'flex',
        flexDirection: 'column',
        
    },
    servicesButton: {
        position:'absolute',
        width:249,
        height:52,
        left:60,
        top:558,
    },
    galleryButton: {
        position:'absolute',
        width:249,
        height:52,
        left:60,
        top:624,
    },
    buttonNotDesktop: {
        width:249,
        height:52,
    },
    largeImage: {
        position: "absolute",
        height: "739px",
        left: "579px",
        right: "0px",
        top: "0px",
        background: 'url(images/HomeImageLarge.png)',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        zIndex: 1,
    },
    largeImageNotDesktop: {
        display: 'none',
    },
    smallImage: {
        position: "absolute",
        width: "368px",
        height: "472px",
        right: "0px",
        top: "362px",
        background: 'url(images/HomeImageSmall.png)',
        zIndex: 2,
    },
    smallImageNotDesktop: {
        display: 'none',
    }
} */
.App {
  position: relative;
  min-height: 100vh;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
}

/** Global Styles */
:root {
  /** Primary Colors */
  --primary-900: #F35399;
  --primary-500: #FC7FBB;
  --primary-100: #FFB8DE;
  /** Neutral Colors */
  --neutral-900: #1A1114;
  --neutral-700: #352B2F;
  --neutral-500: #62585C;
  --neutral-300: #9B9598;
  --neutral-100: #DBD6D8;
  --neutral-white: #F4F4F4;
}

/* Mobile */
@media only screen and (max-width: 899px) {

}

/* Tablet */
@media only screen and (min-width: 899px) {

}

/* Desktop */
@media only screen and (min-width: 1440px) {

}